<template>
  <v-card>
    <v-card-title class="v-card">
      <span class="headline">Detalhes</span>
      <v-spacer />
      <v-btn type="button" color="primary" @click="$emit('close')"
        >Fechar</v-btn
      >
    </v-card-title>
    <v-card-text class=""
      ><v-timeline class="px-2 py-8" :dense="$vuetify.breakpoint.smAndDown">
        <v-timeline-item
          v-for="(item, index) of [...historico].reverse()"
          :key="index"
        >
          <v-card class="elevation-2 pa-4">
            <v-card-text>
              <div>
                <span>
                  Peso:
                  <strong>{{ item.peso }}</strong> Kg</span
                >
              </div>
              <div>
                <span>
                  Tipo:
                  <strong>{{ item.tipo_pesagem | getNameById }}</strong>
                </span>
              </div>
              <div>
                <span>
                  Data:
                  <strong>{{ item.data }}</strong>
                </span>
              </div>
              <div>
                <span>
                  Pesado por:
                  <strong>{{ item.created_by }}</strong>
                </span>
              </div>
            </v-card-text>
          </v-card>
        </v-timeline-item>
      </v-timeline>
    </v-card-text>
  </v-card>
</template>
<script>
import { customChange } from '@/mixins/autocomplete.js'
import { toLocaleDateTimeString } from '@/mixins/convertion'

import { getNameById } from '@/utils/pesagem'

export default {
  mixins: [customChange],
  props: {
    historico: {
      type: Array,
      required: true,
    },
  },
  filters: {
    toLocaleDateTimeString(item) {
      if (item == null) return ''
      return toLocaleDateTimeString(item)
    },
    getNameById(item) {
      if (item == null) return ''
      return getNameById(item)
    },
  },
}
</script>

<style>
.v-timeline-item:last-child {
  padding-bottom: 0 !important;
}
</style>
<style lang="scss" scoped>
.v-card {
  .v-card__text {
    padding-bottom: 0;
  }
}
</style>
