<template>
  <div class="mt-4">
    <Cobranca
      @close="closeModalCobranca"
      @cobrar="cobrar"
      :HORAS_TOLERADAS="HORAS_TOLERADAS"
      :dialog="dialogCobranca"
      :agendamento="agendamentoCobrar"
    ></Cobranca>
    <TrocarCota
      v-if="dialogTrocarCota"
      @close="closeModalTrocarCota"
      :dialog="dialogTrocarCota"
      :agendamento="agendamentoTrocarCota"
    />
    <Pesagem
      v-if="dialogPesagem"
      @close="closeModalPesagem"
      :dialog="dialogPesagem"
      :agendamento="agendamentoPesagem"
    />
    <div style="margin: 20px">
      <v-btn
        color="info"
        class="w-100 my-1"
        @click="getDownload"
        :disabled="!canDownload"
      >
        <v-icon class="mx-2" left dark> mdi-download </v-icon>
        Baixar Planilha</v-btn
      >
    </div>
    <v-dialog v-model="modalChamarCaminhoes" max-width="500px">
      <modal-chamar-caminhoes
        :callback="chamarCaminhoes"
        :terminalDestino.sync="terminalDestino"
        :quantidade="quantidadeSelecionada"
        ref="modal"
      />
    </v-dialog>
    <v-row>
      <v-col cols="11"></v-col>
      <v-col cols="1">
        <v-btn
          v-if="ehFornecedor || ehGate"
          color="primary"
          class="mx-2"
          :disabled="quantidadeSelecionada <= 0"
          @click="showModalChamarVeiculos"
          >Chamar ({{ quantidadeSelecionada }})</v-btn
        >
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="orderedListagem"
      :item-class="itemRowBackground"
      :server-items-length="dataTotal"
      :footer-props="{
        itemsPerPageAllText: `Todos (${dataTotal})`,
        itemsPerPageOptions: [pageSize].concat(
          [15, 50, 100, 500, 1000, 2000].filter((el) => el != pageSize)
        ),
      }"
      :page.sync="paginaAtual"
      :items-per-page="pageSize"
      @update:options="$emit('handleGet', $event)"
      @update:page="$emit('getItemsByPage', $event)"
      @update:items-per-page="
        $emit('getItemsByItemsPerPage', paginaAtual, $event)
      "
      class="border"
    >
      <template v-slot:[`item.status`]="{ item }">
        <v-chip color="primary">{{ item.status }}</v-chip>
      </template>
      <template v-slot:[`item.agendado_em`]="{ item }">
        {{
          item.agendado_em ? new Date(item.agendado_em).toLocaleString() : '-'
        }}
      </template>
      <template v-slot:[`item.cancelado_em`]="{ item }">
        {{
          item.cancelado_em ? new Date(item.cancelado_em).toLocaleString() : '-'
        }}
      </template>
      <template v-slot:[`item.auto_ent_triagem`]="{ item }">
        {{ item.auto_ent_triagem ? 'Autorizado' : 'Não autorizado' }}
      </template>
      <template v-slot:[`item.vaga.setor.bonificada`]="{ item }">
        {{
          item.vaga
            ? item.vaga.setor.bonificada
              ? 'Bonificada'
              : 'Não bonificada'
            : '-'
        }}
      </template>
      <template v-slot:[`item.senhas`]="{ item }">
        {{ item.senhas.length > 0 ? item.senhas[0].sequencia : '-' }}
      </template>
      <template v-slot:[`item.triado_em`]="{ item }">
        {{ item.triado_em ? new Date(item.triado_em).toLocaleString() : '-' }}
      </template>
      <template v-slot:[`item.data_cota`]="{ item }">
        <div v-if="item.data_cota">
          <span v-if="cota_dia_todo(item.data_cota)">{{
            item.data_cota.data_inicio | iso2br
          }}</span>
          <span v-else-if="cota_mesmo_dia(item.data_cota)">
            {{ mostra_cota_mesmo_dia(item.data_cota) }}</span
          >
          <span v-else>
            {{ item.data_cota.data_inicio | toBrazilianDateTime }}
            -
            {{ item.data_cota.data_fim | toBrazilianDateTime }}
          </span>
        </div>
        <div v-else>Não definida</div>
      </template>
      <template v-slot:[`item.data_janela`]="{ item }">
        <div v-if="item.data_janela">
          {{
            item.data_janela &&
            item.data_janela.data_inicio | toBrazilianDateTimeAdded
          }}
          -
          {{
            item.data_janela &&
            item.data_janela.data_fim | toBrazilianDateTimeAdded
          }}
        </div>
        <div v-else>Não definida</div>
      </template>
      <template v-slot:[`item.saida_do_patio`]="{ item }">
        {{
          item.saida_do_patio
            ? new Date(item.saida_do_patio).toLocaleString()
            : '-'
        }}
      </template>
      <template v-slot:[`item.chegada_terminal`]="{ item }">
        {{
          item.chegada_terminal
            ? new Date(item.chegada_terminal).toLocaleString()
            : '-'
        }}
      </template>
      <template v-slot:[`item.data_pedido`]="{ item }">
        {{ item.data_pedido | date2br(item.data_pedido) }}
      </template>
      <template v-slot:[`item.tempoEmPatio`]="{ item }">
        {{ getTempoEmPatio(item) }}
      </template>
      <template v-slot:[`item.pedidos_agendados.numero_pedido`]="{ item }">
        <div class="div-pedido-info">
          <p v-for="(pedido, index) in item.pedidos_agendados" :key="index">
            {{ pedido.numero_pedido }}
          </p>
        </div>
      </template>
      <template v-slot:[`item.pedidos_agendados.num_pedido`]="{ item }">
        <div class="div-pedido-info">
          <p v-for="(pedido, index) in item.pedidos_agendados" :key="index">
            {{ pedido.num_pedido }}
          </p>
        </div>
      </template>
      <template v-slot:[`item.pedidos_agendados.numero_nfe`]="{ item }">
        <div class="div-pedido-info">
          <p v-for="(pedido, index) in item.pedidos_agendados" :key="index">
            {{ pedido.numero_nfe }}
          </p>
        </div>
      </template>
      <template v-slot:[`item.pedidos_agendados.produto`]="{ item }">
        <div class="div-pedido-info">
          <p v-for="(pedido, index) in item.pedidos_agendados" :key="index">
            {{ pedido.produto }}
          </p>
        </div>
      </template>
      <template v-slot:[`item.pedidos_agendados.sub_produto`]="{ item }">
        <div class="div-pedido-info">
          <p v-for="(pedido, index) in item.pedidos_agendados" :key="index">
            {{ pedido.sub_produto }}
          </p>
        </div>
      </template>
      <template v-slot:[`item.pedidos_agendados.quantidade`]="{ item }">
        <div class="div-pedido-info">
          <p v-for="(pedido, index) in item.pedidos_agendados" :key="index">
            {{ converteKg(pedido.quantidade) }}
          </p>
        </div>
      </template>
      <template v-slot:[`item.pedidos_agendados.embalagem`]="{ item }">
        <div class="div-pedido-info">
          <p v-for="(pedido, index) in item.pedidos_agendados" :key="index">
            {{ pedido.embalagem }}
          </p>
        </div>
      </template>
      <template v-slot:[`item.nota_fiscal`]="{ item }">
        {{
          item.pedidos_agendados.length > 0
            ? item.pedidos_agendados[0].numero_nfe
            : '-'
        }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              class="mr-2 mb-2"
              dark
              small
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              class="pointer"
              @click="handleVisualizarComprovantes(item)"
              >Comprovantes</v-list-item
            >
            <v-list-item
              v-if="canViewScheduleReceipt"
              class="pointer"
              @click="handleVisualizarComprovanteAgendamento(item)"
              >Comprovante do Agendamento</v-list-item
            >
            <v-list-item
              v-if="canEntradaBuffer || canSaidaBuffer"
              class="pointer"
              @click="handleVisualizarLogsIntegracoes(item)"
              >Logs de Integracoes</v-list-item
            >
            <v-list-item
              v-if="canViewScheduleReceipt"
              class="pointer"
              @click="handleVisualizarOrdemCarga(item)"
              >Ordem de Carga</v-list-item
            >
            <v-list-item
              class="pointer"
              @click="handleVisualizarDocumentos(item)"
            >
              Documentos
            </v-list-item>
            <template v-if="agendamentoAgendado(item) && !isRelatorio">
              <v-list-item
                class="pointer"
                v-if="empresaAtual.gate && podeTriar(item)"
                @click="handleGerarTriagem(item)"
                >Gerar triagem</v-list-item
              >
              <v-list-item
                class="pointer"
                v-if="
                  !isRelatorio &&
                  canEnviarCadastroMotorista &&
                  empresaAtual.gate
                "
                @click="handleCadastraMotoristaRonda(item)"
              >
                Enviar cadastro do motorista ao Ronda
              </v-list-item>
            </template>
            <template v-else-if="agendamentoNaoConcluido(item) || isRelatorio">
              <v-list-item
                v-if="canViewSortingReceipt"
                @click="handleVisualizarComprovante(item)"
                class="pointer"
                title="Visualizar Comprovante da Triagem"
              >
                Comprovante da Triagem
              </v-list-item>
              <v-list-item
                v-if="canViewSortingReceipt"
                @click="handleVisualizarComprovantePesagem(item)"
                class="pointer"
                title="Visualizar Comprovante de Pesagem"
              >
                Comprovante de Pesagem
              </v-list-item>
              <v-list-item
                v-if="canViewCleaningReceipt && !laudoPreenchido(item)"
                class="pointer"
                @click="openModalGerarChecklistLaudo(item)"
                >Gerar Laudo Limpeza</v-list-item
              >
              <v-list-item
                v-if="canViewCleaningReceipt && laudoPreenchido(item)"
                class="pointer"
                @click="handleVisualizarComprovanteLaudo(item)"
                >Laudo de Limpeza</v-list-item
              >
              <v-list-item
                class="pointer"
                v-if="
                  !isRelatorio && canSendClassification && empresaAtual.gate
                "
                @click="handleEnviaClassificacao(item)"
              >
                Enviar para classificacao
              </v-list-item>
              <v-list-item
                class="pointer"
                v-if="
                  !isRelatorio && canSendClassification && empresaAtual.gate
                "
                @click="handleEnviaPortoNet(item)"
              >
                Enviar PortoNet
              </v-list-item>

              <v-list-item
                class="pointer"
                v-if="canSendClassification && empresaAtual.gate"
                @click="handleFinalizaClassificacao(item)"
              >
                Finalizar classificacao
              </v-list-item>
              <v-list-item
                class="pointer"
                v-if="
                  !isRelatorio && canSendClassification && empresaAtual.gate
                "
                @click="handleEnviarComandoCancela(item)"
              >
                Enviar comando cancela
              </v-list-item>
              <v-list-item
                class="pointer"
                v-if="
                  !isRelatorio && canSendClassification && empresaAtual.gate
                "
                @click="handleResetarClassificacao(item)"
              >
                Resetar classificacao
              </v-list-item>
              <v-list-item
                class="pointer"
                v-if="!isRelatorio && canAdditionalService"
                @click="handleEditarAgendamento(item)"
              >
                Gerar serviço adicional
              </v-list-item>

              <v-list-item
                class="pointer"
                v-if="!isRelatorio && canEntradaBuffer"
                @click="handleEntradaBuffer(item)"
              >
                Entrada buffer
              </v-list-item>
              <v-list-item
                class="pointer"
                v-if="!isRelatorio && canSaidaBuffer"
                @click="handleSaidaBuffer(item)"
              >
                Saida buffer
              </v-list-item>
              <v-list-item
                class="pointer"
                v-if="!isRelatorio && canSaidaBuffer"
                @click="handleEncerrarViagem(item)"
              >
                Encerrar Operação
              </v-list-item>

              <confirm-dialog @confirm="confirmDelete" />
              <v-list-item
                @click="openDialogPesagemHistory(item)"
                class="pointer"
                >Histórico de pesagens</v-list-item
              >

              <template v-if="!isRelatorio">
                <template
                  v-if="
                    canAdvanceSteps &&
                    (empresaAtual.gate ||
                      (item.etapa_atual &&
                        empresaAtual.public_id ===
                          item.etapa_atual.prestador) ||
                      empresaAtual.public_id === item.fornecedor_id ||
                      empresaAtual.public_id === item.terminal_id)
                  "
                >
                  <v-list-item
                    class="pointer"
                    @click="() => handleNextStep(item)"
                    >Avançar etapa</v-list-item
                  >
                </template>
                <v-list-item
                  v-if="
                    canBackoffSteps &&
                    (empresaAtual.gate ||
                      (item.etapa_atual &&
                        empresaAtual.public_id ===
                          item.etapa_atual.prestador) ||
                      empresaAtual.public_id === item.fornecedor_id)
                  "
                  class="pointer"
                  @click="() => handlePreviousStep(item)"
                  >Recuar etapa</v-list-item
                >
                <v-list-item
                  v-if="canPause && item.status !== 'EM PAUSA'"
                  @click="openModalPausarAgendamento(item)"
                  class="pointer"
                  >Pausar</v-list-item
                >
                <v-list-item
                  v-if="canPause"
                  @click="openDialogDetailsPause(item.public_id)"
                  class="pointer"
                  >Log de pausa</v-list-item
                >
                <v-list-item @click="openDialogPesagem(item)" class="pointer"
                  >Pesagem Manual</v-list-item
                >
                <v-list-item
                  v-if="canPause && item.status == 'EM PAUSA'"
                  @click="handleStopPause(item)"
                  class="pointer"
                  >Finalizar pausa</v-list-item
                >
              </template>
            </template>
            <v-list-item
              v-if="
                canCancel &&
                ((empresaAtual.gate && item.triado) || !item.triado) &&
                item.status != 'CANCELADO'
              "
              @click="openModalCancelarAgendamento(item)"
              class="pointer"
              >Cancelar</v-list-item
            >
            <v-list-item
              v-if="!item.auto_ent_triagem"
              @click="handleAutorizarAgendamento(item)"
              class="pointer"
              >Autorizar entrada na Triagem</v-list-item
            >
            <v-list-item
              v-if="item.auto_ent_triagem"
              @click="handleRecusarAgendamento(item)"
              class="pointer"
              >Recusar entrada na Triagem</v-list-item
            >
            <v-list-item
              v-if="
                ((empresaAtual.gate && item.triado) ||
                  (empresaAtual.gate && !item.triado)) &&
                item.status == 'CANCELADO'
              "
              @click="handleEstornarCancelamento(item)"
              class="pointer"
              >Estornar Cancelamento</v-list-item
            >
            <v-list-item
              v-if="canCancelService"
              @click="openModalCancelarServicoTriado(item)"
              class="pointer"
              >Cancelar Serviços Triados</v-list-item
            >
            <v-list-item
              class="pointer"
              v-if="isRelatorio && canCancel"
              @click="handleEstornarStatusInicial(item)"
              >Estornar status inicial</v-list-item
            >
            <v-list-item
              class="pointer"
              v-if="canUpdate"
              @click="handleEditarAgendamento(item)"
              >Editar agendamento</v-list-item
            >
            <v-list-item
              v-if="
                canChangeQuota &&
                (item.programacao_regiao || item.programacao_cliente)
              "
              class="pointer"
              @click="() => openModalTrocarCota(item)"
              >Trocar Cota</v-list-item
            >
          </v-list>
        </v-menu>

        <v-btn
          v-if="canMagnifyingGlass"
          color="primary"
          class="mb-2"
          dark
          small
          @click="openDialogDetails(item.public_id)"
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
        <!-- Adicionando checkbox para chamada -->
        <v-checkbox :value="item.public_id" v-model="selecionados" />
      </template>
    </v-data-table>

    <v-dialog v-model="dialogPesagemHistory" max-width="1080px">
      <historico-pesagem
        @close="dialogPesagemHistory = false"
        :historico="historico"
      />
    </v-dialog>

    <v-dialog v-model="dialogDetails" max-width="1080px">
      <detalhes-gerenciamento-servico
        @close="dialogDetails = false"
        :detalhes="detalhes"
      />
    </v-dialog>

    <v-dialog v-model="dialogDetailsPause" max-width="1080px">
      <detalhes-pause-gerenciamento-servico
        @close="dialogDetailsPause = false"
        :detalhes="detalhesPause"
      />
    </v-dialog>

    <v-dialog v-model="dialogGerarChecklistLaudo" max-width="1400px">
      <v-card>
        <v-card-title class="v-card">
          <v-container fill-height fluid style="display: flex">
            <v-row class="v-input mb-0" style="height: 50px">
              <v-col cols="12" sm="12" md="12" class="text-center">
                <legend class="headline" style="display: block">
                  Preencha o Checklist
                </legend>
              </v-col>
            </v-row>
            <v-row class="v-input mb-0 mt-0">
              <v-col class="mb-0" cols="12" sm="12" md="6">
                <span>Itens</span>
              </v-col>
              <v-col class="mb-0" cols="12" sm="6" md="2">
                <span class="pr-3 pl-3 mr-0">Sim</span>
                <span class="pr-3 pl-3 mr-0">Não</span>
              </v-col>
            </v-row>
            <v-row
              v-for="(item, index) in listItensCheckList"
              :key="index"
              class="v-input mt-0 mb-0"
              style="height: 55px"
            >
              <v-col class="pt-0 pb-0 mt-0 mb-0" cols="12" sm="12" md="12">
                <hr style="border-top: 0px solid #bbb" />
              </v-col>
              <v-col
                class="v-label theme--light pt-4 pb-0"
                cols="12"
                sm="12"
                md="6"
                style="text-transform: uppercase"
              >
                <span :label="item.description">{{ item.description }}</span>
              </v-col>
              <v-col cols="12" sm="12" md="2" class="pt-3 pb-0 mt-0">
                <v-radio-group class="mt-0" row v-model="item.selectedItemKey">
                  <v-radio
                    :color="item.color"
                    :key="item.key"
                    :value="item.key"
                    v-for="item in item.items"
                    class="pr-3 pl-3 mr-0"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" sm="12" md="4" class="pt-0 pb-0">
                <v-text-field
                  v-model="item.observation"
                  :key="index"
                  class="mb-0 mt-3 pt-0"
                  label="Observação"
                  name="observacao"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="black darken-1"
            text
            @click="dialogGerarChecklistLaudo = false"
          >
            Cancelar
          </v-btn>
          <v-btn type="button" color="success" @click="handleGerarLaudo()"
            >Gerar Laudo de Limpeza
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogCancelar" max-width="500px">
      <v-card>
        <v-card-title class="v-card">
          <fieldset>
            <legend class="headline">Selecione o motivo para cancelar</legend>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-autocomplete
                  v-model="selectMotivoCancelamento"
                  label="Motivo de Cancelamento"
                  :items="motivoscancelamento"
                  item-text="descricao"
                  item-value="public_id"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="motivosCancelamentoObservacao"
                  class="mb-4"
                  label="Observação"
                  name="observacao"
                  hint="Motivo do Cancelamento"
                  persistent-hint
                />
              </v-col>
            </v-row>
          </fieldset>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="black darken-1" text @click="dialogCancelar = false"
            >Cancelar</v-btn
          >
          <v-btn
            type="button"
            color="error"
            @click="handleCancelarAgendamento()"
            >Confirmar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogServicoTriadoCancelar" max-width="500px">
      <v-card>
        <v-card-title class="v-card">
          <span class="headline">Informe os motivos para cancelar:</span>
        </v-card-title>
        <v-card-text>
          <v-textarea
            v-model="servicoTriadoCancelar.motivo_cancelamento"
            full-width
            single-line
            label="Motivos..."
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="black darken-1"
            text
            @click="dialogServicoTriadoCancelar = false"
            >Cancelar</v-btn
          >
          <v-btn
            type="button"
            color="error"
            @click="handleCancelarServicoTriado()"
            >Confirmar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogPausar" max-width="500px">
      <v-card>
        <v-card-title class="v-card">
          <span class="headline">Informe os motivos para pausar:</span>
        </v-card-title>
        <v-card-text>
          <v-textarea
            v-model="agendamentoPause.observacao"
            full-width
            single-line
            label="Motivos..."
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="black darken-1" text @click="dialogPausar = false"
            >Cancelar</v-btn
          >
          <v-btn type="button" color="error" @click="handlePauseAgendamento()"
            >Pausar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogServicosTriados" max-width="500px">
      <v-card>
        <v-card-title class="v-card">
          <span class="headline">Cancelar serviços triados:</span>
        </v-card-title>
        <v-card-text>
          <div class="mt-4">
            <v-data-table
              :headers="headersServicoTriado"
              :items="servicosTriados"
              sort-by="descricao"
              class="border"
            >
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon small @click="cancelarServicoTriado(item)"
                  >mdi-delete</v-icon
                >
              </template>
            </v-data-table>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="black darken-1"
            text
            @click="dialogServicosTriados = false"
            >Fechar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { props } from './_props'
import { data } from './_data'
import { computed } from './_computed'
import { methods } from './_methods'
import { dateAgendamento, date2br } from '@/mixins/convertion'
import {
  cota_dia_todo,
  cota_mesmo_dia,
  mostra_cota_mesmo_dia,
} from '@/mixins/cotas.js'
import ConfirmDialog from '@/components/template/ConfirmDialog'
import ModalChamarCaminhoes from '@/components/SolicitacaoBaixas/ModalChamarCaminhoes.vue'
import DetalhesGerenciamentoServico from '../DetalhesGerenciamentoServico'
import DetalhesPauseGerenciamentoServico from '../DetalhesPauseGerenciamentoServico'
import HistoricoPesagem from '../HistoricoPesagem'
import TrocarCota from '../TrocarCota'
import Cobranca from '../cobranca/Cobranca'
import Pesagem from '../Pesagem'

const HORAS_TOLERADAS = 30

export default {
  inject: {
    visualizarComprovante: {
      from: 'visualizarComprovante',
    },
    visualizarComprovantePesagem: {
      from: 'visualizarComprovantePesagem',
    },
    visualizarComprovantes: {
      from: 'visualizarComprovantes',
      default: () => {},
    },
    visualizarComprovanteAgendamento: {
      from: 'visualizarComprovanteAgendamento',
    },
    visualizarLogsIntegracoes: {
      from: 'visualizarLogsIntegracoes',
      default: () => {},
    },
    visualizarOrdemDeCarga: {
      from: 'visualizarOrdemDeCarga',
    },
    visualizarComprovanteLaudo: {
      from: 'visualizarComprovanteLaudo',
    },
    visualizarComprovanteServicoAdicional: {
      from: 'visualizarComprovanteServicoAdicional',
      default: () => {},
    },
    visualizarDocumentos: {
      from: 'visualizarDocumentos',
      default: () => {},
    },
    showShortcurt: {
      from: 'showShortcurt',
      default: false,
    },
  },
  mixins: [
    dateAgendamento,
    date2br,
    cota_dia_todo,
    cota_mesmo_dia,
    mostra_cota_mesmo_dia,
  ],
  props,
  components: {
    DetalhesGerenciamentoServico,
    ModalChamarCaminhoes,
    DetalhesPauseGerenciamentoServico,
    HistoricoPesagem,
    Cobranca,
    TrocarCota,
    ConfirmDialog,
    Pesagem,
  },
  created() {
    this.HORAS_TOLERADAS = HORAS_TOLERADAS
  },
  data,
  computed,
  methods,
  watch: {
    'empresaAtual.public_id': {
      handler() {
        this.$emit('cleanData')
      },
    },
  },
  filters: {
    toBrazilianDateTime(date) {
      if (!date) return 'Não Definida'
      return new Date(date).toLocaleString('pt-br')
    },
    toBrazilianDateTimeAdded(date) {
      if (!date) return 'Não Definida'
      const data = new Date(date)
      const add_3_hours = 1000 * 60 * 60 * 3
      data.setTime(data.getTime() + add_3_hours)
      return data.toLocaleString('pt-br')
    },
    iso2br(date) {
      if (!date) return 'Não Definida'
      // Remove Z
      let data_splited = date.split('Z')[0]
      // Split DATE and TIME
      data_splited = data_splited.split('T')
      const data = data_splited[0].split('-')
      if (data.length != 3) return 'Não Definida'
      return `${data[2]}/${data[1]}/${data[0]}`
    },
  },
}
</script>
<style>
.style-1 {
  background-color: white;
}

.style-2 {
  background-color: red;
}
</style>
<style lang="scss" scoped>
.status--cancelado {
  padding: 10px 15px;
  background: rgb(243, 72, 72);
  border-radius: 10px;
  color: white;
}

.status--triagem {
  padding: 10px 15px;
  background: rgb(64, 228, 64);
  border-radius: 10px;
}

.pointer {
  cursor: pointer;
}

.button-submit-orange {
  background: red;
}

.div-pedido-info {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  text-align: center;
}

.div-pedido-info p {
  margin: 5px 0;
}

div {
  margin-bottom: 5px;
}

p,
label {
  font: 1rem 'Fira Sans', sans-serif;
  text-transform: uppercase;
  margin-left: 8px;
}

input {
  min-width: 20px;
  min-height: 20px;
  margin-right: 5px;
}

legend {
  margin-bottom: 20px;
}
</style>
