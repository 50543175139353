import PatioApi from '@/services/patio'

export const methods = {
  async showLog(item) {
    const log = await PatioApi.getLogIntegracao(item)
    this.log = log
    this.show_log = true
  },
  getColor(item) {
    return item.status in this.status_color
      ? this.status_color[item.status]
      : this.status_color['0']
  },
  close() {
    this.show_log = false
  },
}
