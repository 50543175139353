import {
  MyIntegerEnum,
  getById as _getById,
  getNameById as _getNameById,
} from './enum'

const Pesagem = {
  Tara: MyIntegerEnum.create(1, 'Tara'),
  Bruto: MyIntegerEnum.create(2, 'Bruto'),
  Operacao: MyIntegerEnum.create(3, 'Operacao'),
  PBO: MyIntegerEnum.create(4, 'PBO'),
  PLO: MyIntegerEnum.create(5, 'PLO'),
}

const getById = (id) => {
  return _getById(Pesagem, id)
}
const getNameById = (id) => {
  return _getNameById(Pesagem, id)
}

export { Pesagem, getById, getNameById }
